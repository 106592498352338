.MuiButtonBase-root.MuiIconButton-root.close-button {
  width: 48px;
  height: 48px;

  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px);

  z-index: 803;
  pointer-events: initial !important;
}

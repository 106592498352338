.product-info-modal {
  .product-thumbnail {
    width: 100%;
    height: 160px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: auto;
      height: 78%;
    }
  }

  .product-info {
    width: 100%;
    flex: 1;

    padding: 0 24px;

    .furniture-button {
      width: 100%;
      margin: 6px 0;
    }
  }
}

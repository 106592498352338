.sign-in {
  padding-top: 72px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(24px);

  .sign-in-content {
    width: 100%;

    padding: 0 24px;

    display: flex;
    flex-direction: column;
    align-items: center;

    > .app-text {
      font-family: 'Poppins';
      line-height: 30px;
      text-align: center;
    }

    .sign-in-form {
      width: 100%;
      margin-top: 8px;

      .email-control {
        width: 100%;
        position: relative;

        .app-text {
          font-family: Poppins;
          line-height: 18px;
        }

        label + .email-input {
          margin-top: 24px;
        }

        .email-input {
          width: 100%;
          height: 50px;
          position: relative;

          border-radius: 6px;
          background: #fff;
          border: 2px solid #fff;

          padding: 0 16px;

          .MuiInputBase-input {
            color: #000;
            font-family: Poppins;
            font-size: 14px;
            line-height: 18px;

            &::placeholder {
              color: #999;
              font-family: Poppins;
              font-size: 14px;
            }
          }

          &.Mui-error {
            border: 2px solid #bd0100;

            .MuiInputBase-input {
              color: #bd0100;
              font-weight: 500;
            }
          }
        }

        .email-helper-text {
          width: 100%;
          height: 50px;

          margin: 0;
          padding: 16px;

          border-radius: 6px;
          background: #bd0100;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

          position: absolute;
          top: 74px;
          left: 0px;

          z-index: 802;

          .MuiTypography-root.app-text {
            text-align: right;
            font-family: 'SF Pro Text';
            line-height: normal;
          }

          &::after {
            content: '';
            position: absolute;
            top: -18px;
            left: calc(100% - 38px);
            border-top: 8px solid transparent;
            border-bottom: 8px solid #bd0100;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
          }
        }
      }

      .furniture-button {
        width: 100%;
        margin-top: 32px;

        .app-text {
          font-family: 'Poppins';
          font-weight: 600;
          letter-spacing: -0.408px;
        }
      }
    }

    .sign-in-tc {
      width: 100%;

      margin-top: 16px;
      padding: 16px;

      border-radius: 4px;
      background: rgba(0, 0, 0, 0.5);

      display: flex;
      flex-direction: row;

      .MuiFormControlLabel-root.app-checkbox-control {
        margin: 0;
        align-items: initial;

        .app-text {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          padding-left: 16px;
        }

        a {
          color: #f68c2c;
          font-weight: 500;
        }
      }
    }
  }

  .skip {
    text-align: center;
    text-decoration: none;
    margin-bottom: 36px;

    .MuiTypography-root.app-text {
      font-family: Poppins;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.408px;
      text-transform: capitalize;
    }

    &.Mui-disabled .MuiTypography-root.app-text {
      opacity: 0.4;
    }
  }

  .modal-content {
    padding: 24px;
    justify-content: space-between;

    .terms-text {
      width: 100%;
      height: calc(100% - 72px);

      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      overflow-x: hidden;

      .terms-link {
        color: #5f85ed;
      }
    }

    .terms-done {
      width: 100%;
      height: 40px;

      .MuiTypography-root {
        font-family: Poppins;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }
}

.recommendations-modal {
  .modal-content {
    display: flex;
    flex-direction: column;

    .recommendations-section {
      width: 100%;
      height: 128px;

      display: flex;
      align-items: center;
      justify-content: center;

      .recommendations-carousel {
        width: 100%;
        display: flex;
        overflow-x: scroll;
        padding: 0 8px;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          display: none;
        }

        .recommendation-card {
          border-radius: 8px;
          margin: 0 4px;

          .recommendation-card-content {
            width: 64px;
            height: 64px;
            padding: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 90%;
              height: auto;
            }
          }
        }
      }
    }

    .recommendations-buttons {
      width: 100%;
      flex: 1;

      padding: 6px 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .recommendation-button {
        width: 48%;
        max-height: 40px;

        .app-text {
          color: #e5e5ea;
          text-transform: initial;
          font-weight: 500;
        }
      }
    }
  }
}

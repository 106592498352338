@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

html.a-fullscreen body {
  width: 100vw;
  height: 100vh;
}

body {
  width: 100vw;
  height: 100vh;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  margin: 0;
  padding: 0;

  .MuiTypography-root.app-text {
    color: #ffffff;
  }
}

#camera-feed {
  width: 100%;
  height: 100%;
  object-fit: cover;

  position: absolute;
  top: 0;
  left: 0;

  display: none;

  pointer-events: none;
}

.MuiButtonBase-root.MuiButton-root.furniture-button {
  width: 88%;
  height: 48px;

  background-color: #f68c2c;
  border-radius: 8px;

  text-transform: none;

  &.Mui-disabled {
    opacity: 0.4;
  }
}

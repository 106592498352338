@keyframes scan-move {
  from {
    left: 0%;
  }
  to {
    left: 75%;
  }
}

.scan-screen {
  display: flex;

  .scan-instructions,
  .scan-recording,
  .scan-done {
    width: 100%;
    height: 100%;

    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    z-index: 802;
  }

  .scan-instructions {
    .scan-content {
      width: 100%;

      flex: 1;

      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      top: 34px;

      .scan-action {
        width: 90%;
        max-width: 355px;

        display: flex;
        flex-direction: column;

        .scan-hint {
          width: 100%;
          height: 100px;
          position: relative;

          svg {
            width: auto;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;

            animation: scan-move 3000ms infinite;
            animation-delay: 200ms;
            animation-timing-function: linear;
          }
        }

        .scan-arrow {
          width: 100%;
          position: relative;

          svg {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }

    .scan-footer {
      width: 92%;
      max-width: 355px;

      padding: 16px;
      margin-bottom: 16px;

      border-radius: 16px;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(24px);

      .app-text {
        text-align: center;
      }

      .furniture-button {
        width: 100%;
      }
    }
  }

  .scan-recording {
    justify-content: flex-end;

    .scan-countdown {
      width: 60px;
      margin-top: '16px';

      border-radius: 50px;
      background: #e20000;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .scan-done {
    background: rgba(0, 0, 0, 0.4);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .scan-done-dialog {
      width: 90%;
      max-width: 355px;

      padding: 16px 24px 24px 24px;

      border-radius: 16px;
      background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(24px);

      display: flex;
      flex-direction: column;
      align-items: center;

      .scan-done-options {
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .MuiButtonBase-root.MuiButton-root.furniture-button {
          width: 47%;

          &:first-child {
            background-color: #fff;
          }
        }
      }
    }
  }
}

.no-model {
  .no-model-hint {
    height: 56px;
    background: rgba(255, 159, 10, 0.4);
    backdrop-filter: blur(24px);

    text-align: center;

    padding: 0 24px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-model-illustration {
    height: calc(100% - 56px);

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: auto;
      height: 70%;
    }
  }
}

.MuiButtonBase-root.MuiIconButton-root.ar-button {
  width: 60px;
  height: 60px;

  background: rgba(0, 0, 0, 0.6);
  border: 2px solid #ffffff;
  border-radius: 100%;

  padding: 0;
  pointer-events: initial;
}

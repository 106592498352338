.screen {
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;

  position: absolute;
  top: 0;
  left: 0;

  z-index: 802;
}

.furniture-screen {
  pointer-events: none;

  display: flex;
  flex-direction: column;
  align-items: center;

  .tap-prompt {
    width: 92%;
    max-width: 360px;

    padding: 26px 24px 26px 24px;
    margin-top: 80px;

    border-radius: 16px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(24px);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .tap-prompt-info {
      width: 78.83%;

      display: flex;
      flex-direction: column;

      .tap-prompt-title {
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.408px;
      }
    }

    .tap-prompt-icon {
      width: 60px;
      height: auto;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .reposition-prompt {
    width: 92%;
    max-width: 360px;

    padding: 16px;
    margin-top: 80px;

    border-radius: 16px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(24px);

    display: flex;
    flex-direction: column;

    .reposition-prompt-title {
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.408px;
      text-align: center;
    }

    .reposition-prompt-info {
      width: 100%;
      margin-top: 16px;

      display: flex;
      flex-direction: row;

      .reposition-icon {
        width: 50%;

        display: flex;
        flex-direction: column;
        align-items: center;

        .MuiTypography-root {
          text-align: center;
          line-height: normal;
          letter-spacing: -0.408px;
        }
      }
    }
  }

  .restart-button {
    width: 48px;
    height: 48px;

    align-self: end;
    margin-top: 80px;
    margin-right: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);

    pointer-events: initial;
  }

  .ar-buttons {
    width: 25%;
    min-width: 390px;
    height: 108px;

    padding: 0 48px;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    position: absolute;
    bottom: 0;
  }

  .modal {
    pointer-events: initial;

    .preview-container {
      width: 100%;
      height: calc(100% - 90px);

      display: flex;
      align-items: center;
      justify-content: center;

      .preview {
        width: auto;
        height: 80%;

        overflow: hidden;
        border: 2px solid #ffffff;
        border-radius: 8px;

        img {
          width: auto;
          height: 100%;
        }
      }
    }

    .share-container {
      width: 100%;
      height: 90px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.terms-and-conditions {
  .modal-content {
    padding: 24px;
  }

  .terms-illustration {
    width: 100%;
    height: 56%;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: auto;
      height: 80%;
    }
  }

  .terms-text {
    width: 100%;
    height: 80%;

    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .terms-link {
    color: #5f85ed;
  }

  .terms-footer {
    width: 100%;

    display: flex;
    flex-direction: column-reverse;
    flex: 1;

    .more-button {
      padding-top: 16px;
      justify-content: flex-start;
      padding: 0;

      .MuiTypography-root.MuiTypography-subtitle2 {
        color: #f68c2c !important;
        text-transform: none;
        line-height: 20px;
      }
    }

    .terms-notice {
      padding: 16px;
      margin-top: 16px;

      background: rgba(0, 0, 0, 0.5);
      border-radius: 4px;

      display: flex;
      flex-direction: row;

      .app-text {
        padding-left: 16px;
      }
    }

    .continue-button {
      height: 50px;
      margin-top: 16px;
      text-transform: none;

      .MuiTypography-root {
        color: #1c1b1f !important;
      }

      &.Mui-disabled {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.overlay-prompt {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: rgba(0, 0, 0, 0.3);

  .overlay-content {
    width: 90%;
    max-width: 355px;

    padding: 16px 24px 16px 24px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .overlay-instructions {
      width: 88%;
      height: auto;
      margin-top: 24px;
      margin-bottom: 8px;
    }

    .move-device-animation {
      width: auto;
      height: 112px;

      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .overlay-scanning {
    position: absolute;
    bottom: 0;
    margin: 24px;

    .MuiTypography-root.app-text {
      font-family: 'Poppins';
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.408px;
    }
  }
}

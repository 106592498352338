.app-checkbox-control {
  .app-checkbox {
    width: 24px;

    .MuiButtonBase-root.MuiCheckbox-root {
      padding: 0;

      .MuiSvgIcon-root {
        color: #ffffff;
      }

      &.Mui-checked {
        .MuiSvgIcon-root {
          color: #f68c2c;
        }
      }
    }
  }
}

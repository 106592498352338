.modal {
  width: 100%;

  position: fixed;
  left: 0%;

  display: flex;
  flex-direction: column;

  &.full {
    .modal-title-bar {
      border-radius: 0;
    }
  }

  > * {
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(24px);
  }

  .modal-title-bar {
    height: 62px;

    border-radius: 16px 16px 0px 0px;
    padding: 0 16px;

    display: flex;
    flex-direction: row;
    align-items: center;

    .modal-title {
      flex: 1;
      text-align: center;
      font-family: 'Poppins';
    }
  }

  .modal-content {
    height: calc(100% - 62px);

    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: scroll;
  }
}
